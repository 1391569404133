<template>
	<div class="container application-details">
		<div class="row">
			<div class="col-md-10 text-center middle">
				<h3 class="line-lg">Your leasing application has been successfully submitted.</h3>
				<p><strong>Thank you for your interest in leasing your kegs with BCLBeer.com. A representative will be contacting you soon to discuss the specifics of your lease application.</strong></p>
				<p>If you'd like to contact one of our customer service representatives regarding your application, please email us at Service@InstantKegs.com or call <a href="tel:17274999930">(727) 499-9930</a>.</p>
			</div>

			<div class="col-md-6">
				<div class="application-details__box">
					<h4>Items</h4>
					<ul class="list-inline row align-items-center" v-for="(product, key) in application.cart.products" :key="key">
						<li class="col col-1 strong">{{product.quantity}}x</li>
						<li class="col strong">{{product.name}}</li>
						<li class="col">{{product.price | currency}}</li>
						<li class="col"><h5>{{product.quantity * product.price | currency}}</h5></li>
						<li><hr /></li>
					</ul>

					<h4>Finance Est.</h4>
					<dl class="dl-horizontal row">
						<dt class="col-5">Type</dt>
						<dd class="col-7">{{application.cart.finance.type | leaseType}}</dd>
						<dt class="col-5">Estimated Payments</dt>
						<dd class="col-7">
							<div v-for="(payment, key) in application.cart.finance.payments" :key="key">
								<strong>{{payment.term}} months</strong> - {{payment.amount | currency}}/mo
							</div>
						</dd>
					</dl>
				</div>
			</div>

			<div class="col-md-6">
				<div class="application-details__box">
					<h4>Application Details</h4>
					<dl class="dl-horizontal row">
						<dt class="col-4">Application ID</dt>
						<dd class="col-8">{{application.$key | idMask('IK')}}</dd>
						<dt class="col-4">Date</dt>
						<dd class="col-8">{{application.created_at | date('MM/DD/YYYY hh:mmA')}}</dd>
						<dt class="col-4">Subtotal</dt>
						<dd class="col-8">{{application.cart.subTotal | currency}}</dd>
						<dt class="col-4">Taxes</dt>
						<dd class="col-8">{{application.cart.taxes | currency}}</dd>
						<dt class="col-4">Shipping</dt>
						<dd class="col-8">{{application.cart.shipping.method.price | currency}}</dd>
						<dt class="col-4">Total</dt>
						<dd class="col-8">{{application.cart.total | currency}}</dd>
					</dl>

					<h4>Shipping</h4>
					<dl class="dl-horizontal row">
						<dt class="col-4">Contact</dt>
						<dd class="col-8">{{application.cart.shipping.firstName}} {{application.cart.shipping.lastName}}</dd>
						<dt class="col-4">Business</dt>
						<dd class="col-8">{{application.cart.shipping.company}}</dd>
						<dt class="col-4">Address</dt>
						<dd class="col-8">
							{{application.cart.shipping.street1}} {{application.cart.shipping.street2}}<br>
							{{application.cart.shipping.city}}, {{application.cart.shipping.state}} {{application.cart.shipping.zip}}
						</dd>
						<dt class="col-4">Method</dt>
						<dd class="col-8">
							{{application.cart.shipping.method.carrier}}<br>
							{{application.cart.shipping.method.delivery}}
						</dd>
					</dl>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'applicationDetails',
	computed: {
		application() {
			return this.$store.getters['cart/application']
		},
	},
}
</script>

<style scoped lang="scss">
.application-details {
	&__box {
		box-shadow: $box-shadows;
		padding: 0 0 20px;
		background-color: color_('white');

		h4 {
			margin: 0;
			padding: 20px 0;
			text-align: center;
			border-bottom: 1px solid color_('gray','base');
			margin-bottom: 20px;
			background-color: #eee;
			border-bottom: 1px solid #ccc;
		}

		.dl-horizontal {
			margin-right: 0;
			margin-left: 20px;
		}

		ul {
			margin-left: 20px;

			li {
				display: inline;
				padding: 0;
			}
		}

		h5 {
			margin: $line 0;
			font-size: $font-size-h5 * 1.2;
		}
	}
}
</style>
