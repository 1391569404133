<template>
	<div class="wizard-application">
		<form class="form wizard-application__form row" name="form" @submit.prevent.stop="submitForm(application)">

			<div class="col-md-12 text-center">
				<img src="~@/assets/images/bcl-logo-dark.png" width="120" height="70" class="middle line">
			</div>

			<div class="form-group col-md-8">
				<label for="company">Business Name</label>
				<input type="text" class="form-control" id="company" v-model="application.company.name" placeholder="Business Name" required autocomplete="organization">
			</div>

			<div class="form-group col-md-4">
				<label for="type">Industry</label>
				<input type="text" class="form-control" id="type" v-model="application.company.type" placeholder="Beer, Wine, Etc." required>
			</div>

			<div class="form-group col-md-4">
				<label for="company_year">Year Established</label>
				<input type="text" class="form-control" id="company_year" v-model="application.company.established" placeholder="XXXX" required>
			</div>

			<div class="form-group col-md-4">
				<label for="company_employees">Number Employees</label>
				<input type="text" class="form-control" id="company_employees" v-model="application.company.size" placeholder="XX" required>
			</div>

			<div class="form-group col-md-4">
				<label for="company_ein">EIN/Tax ID</label>
				<input type="text" class="form-control" id="company_ein" v-model="application.company.ein" placeholder="XX-XXXXXX" required>
			</div>

			<div class="form-group col-md-4">
				<label for="phone">Phone</label>
				<!-- <input type="tel" class="form-control" id="phone" v-model="application.company.phone" placeholder="(111) 222-3434" data-phone-format required autocomplete="tel"> -->
				<input-format type="tel" class="form-control" id="phone" v-model="application.company.phone" placeholder="(111) 222-3434" format="phone" required autocomplete="tel"></input-format>
			</div>

			<div class="form-group col-md-4">
				<label for="phone2">Alt Phone <small>(optional)</small></label>
				<!-- <input type="tel" class="form-control" id="phone2" v-model="application.company.phone2" placeholder="(111) 222-3344" data-phone-format autocomplete="tel"> -->
				<input-format type="tel" class="form-control" id="phone2" v-model="application.company.phone2" placeholder="(111) 222-3344" format="phone" autocomplete="tel"></input-format>
			</div>


			<div class="form-group col-md-4">
				<label for="fax">Fax <small>(optional)</small></label>
				<!-- <input type="text" class="form-control" id="fac" v-model="application.company.fax" placeholder="(111) 222-3344" data-phone-format> -->
				<input-format type="text" class="form-control" id="fac" v-model="application.company.fax" placeholder="(111) 222-3344" format="phone"></input-format>
			</div>

			<div class="form-group col-md-8">
				<label for="street1">Street Address</label>
				<input type="text" class="form-control" id="street1" v-model="application.company.street1" placeholder="Street Address" required autocomplete="address-line1">
			</div>

			<div class="form-group col-md-4">
				<label for="street2">Suite <small>(optional)</small></label>
				<input type="text" class="form-control" id="street2" v-model="application.company.street2" placeholder="Suite" autocomplete="address-line2">
			</div>

			<div class="form-group col-md-4">
				<label for="zip">Zip</label>
				<input type="text" class="form-control" id="zip" v-model="application.company.zip" placeholder="Zip Code" required autocomplete="postal-code">
			</div>

			<div class="form-group col-md-4">
				<label for="city">City</label>
				<input type="text" class="form-control" id="city" v-model="application.company.city" placeholder="City" required autocomplete="address-level2">
			</div>

			<div class="form-group col-md-4">
				<label for="state">State</label>
				<div class="select-group">
					<select class="form-control" id="state" v-model="application.company.state" autocomplete="address-level1" required>
						<option :value="undefined">&ndash; Select State &ndash;</option>
						<option :value="state.abbreviation" v-for="(state, key) in statesList" :key="key">{{state.name}}</option>
					</select>
				</div>
			</div>

			<h4 class="col-md-12">Primary Owner</h4>

			<div class="form-group col-md-12">
				<label for="owner_name">Full Name</label>
				<input type="text" class="form-control" id="owner_name" v-model="application.owner.name" placeholder="First Last Name" required autocomplete="name">
			</div>

			<div class="form-group col-md-8">
				<label for="street1">Home Address</label>
				<input type="text" class="form-control" id="street1" v-model="application.owner.street1" placeholder="Street Address" required autocomplete="address-line1">
			</div>

			<div class="form-group col-md-4">
				<label for="street2">Suite <small>(optional)</small></label>
				<input type="text" class="form-control" id="street2" v-model="application.owner.street2" placeholder="Suite" autocomplete="address-line2">
			</div>

			<div class="form-group col-md-4">
				<label for="zip">Zip</label>
				<input type="text" class="form-control" id="zip" v-model="application.owner.zip" placeholder="Zip Code" required autocomplete="postal-code">
			</div>

			<div class="form-group col-md-4">
				<label for="city">City</label>
				<input type="text" class="form-control" id="city" v-model="application.owner.city" placeholder="City" required autocomplete="address-level2">
			</div>

			<div class="form-group col-md-4">
				<label for="state">State</label>
				<div class="select-group">
					<select class="form-control" id="state" v-model="application.owner.state" autocomplete="address-level1" required>
						<option :value="undefined">&ndash; Select State &ndash;</option>
						<option :value="state.abbreviation" v-for="(state, key) in statesList" :key="key">{{state.name}}</option>
					</select>
				</div>
			</div>

			<div class="form-group col-md-4">
				<label for="email">Email Address</label>
				<input type="email" class="form-control" id="email" v-model="application.owner.email" placeholder="you@email.com" required autocomplete="email">
			</div>

			<div class="form-group col-md-4">
				<label for="phone">Home Phone</label>
				<!-- <input type="tel" class="form-control" id="phone" v-model="application.owner.phone" placeholder="(111) 222-3434" data-phone-format required autocomplete="tel"> -->
				<input-format type="tel" class="form-control" id="phone" v-model="application.owner.phone" placeholder="(111) 222-3434" format="phone" required autocomplete="tel"></input-format>
			</div>

			<div class="form-group col-md-4">
				<label for="phone2">Cell Phone <small>(optional)</small></label>
				<!-- <input type="tel" class="form-control" id="phone2" v-model="application.owner.phone2" placeholder="(111) 222-3344" data-phone-format autocomplete="tel"> -->
				<input-format type="tel" class="form-control" id="phone2" v-model="application.owner.phone2" placeholder="(111) 222-3344" autocomplete="tel" format="phone"></input-format>
			</div>

			<div class="form-group col-md-4">
				<label for="dob">Date of Birth</label>
				<!-- <input type="tel" class="form-control" id="dob" v-model="application.owner.dob" placeholder="MM/DD/YYYY" data-dob-format autocomplete="" required> -->
				<input-format type="tel" class="form-control" id="dob" v-model="application.owner.dob" placeholder="MM/DD/YYYY" format="dob" autocomplete="" required></input-format>
			</div>

			<div class="form-group col-md-8">
				<label for="social">Social Security #</label>
				<input type="tel" class="form-control" id="social" v-model="application.owner.social" placeholder="000-00-0000" data-social-format autocomplete="" required>
			</div>

			<div class="form-group col-md-8">
				<label for="license_number">Driver License #</label>
				<input type="text" class="form-control" id="license_number" v-model="application.owner.licenseNumber" placeholder="Driver's License Number" required>
			</div>

			<div class="form-group col-md-4">
				<label for="license_state">State Issued</label>
				<div class="select-group">
					<select class="form-control" id="license_state" v-model="application.owner.licenseState" autocomplete="address-level1" required>
						<option :value="undefined">&ndash; Select State &ndash;</option>
						<option :value="state.abbreviation" v-for="(state, key) in statesList" :key="key">{{state.name}}</option>
					</select>
				</div>
			</div>

			<div class="form-group col-md-4">
				<label for="property_type">Property Ownership</label>
				<div class="select-group">
					<select class="form-control" id="property_type" v-model="application.owner.propertyType" required>
						<option :value="undefined">&ndash; Please Choose &ndash;</option>
						<option value="rent">Rent</option>
						<option value="own">Own</option>
					</select>
				</div>
			</div>

			<div class="form-group col-md-4">
				<label for="property_cost">Monthly Payment</label>
				<input type="tel" class="form-control" id="property_cost" v-model="application.owner.propertyCost" placeholder="$0000" required>
			</div>

			<div class="form-group col-md-4">
				<label for="property_length">Duration Occupied</label>
				<input type="tel" class="form-control" id="property_length" v-model="application.owner.propertyLength" placeholder="# of Years" required>
			</div>

			<div class="form-group col-md-12">
				<label for="property_owner" v-html="application.owner.propertyType=='own'?'Mortgage Company':'Landlord Name'"></label>
				<input type="text" class="form-control" id="property_owner" v-model="application.owner.propertyOwner" placeholder="Name" required>
			</div>

			<div class="col-md-12 text-center">
				<button type="submit" class="btn btn-primary center-block" :disabled="loading">Submit Application</button>
			</div>

		</form>
	</div>
</template>

<script>
// Dependencies
import InputFormat from '@/components/InputFormat.vue'

// Component
export default {
	name: 'wizardApplication',
	components: {
		InputFormat,
	},
	computed: {
		loading() {
			return this.$store.getters['cart/loading']
		},
		error() {
			return this.$store.getters['cart/error']
		},
		statesList() {
			return this.$store.getters['misc/statesList']
		},
		cart() {
			return this.$store.getters['cart/cart']
		},
		application() {
			if (this.cart.application) {
				return this.cart.application
			}

			return {
				company: {},
				owner: {},
			}
		},
	},
	methods: {
		submitForm(application) {
			application.cartId = this.cart.$key
			this.$store.dispatch('cart/createApplication', application)
				.then(res => {
					this.$router.push({ name: 'application', params: { applicationId: res.key }})
				})
		},
	},
}
</script>

<style scoped lang="scss">

</style>
